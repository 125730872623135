<template>
  <div class="power-box">
    <div class="power-top">
      <div class="power-group">
        <div class="power-item">
          <span>一级模块</span>
          <span>(</span>
          <el-link type="success" @click="itemAdd">新增模块</el-link>
          <span>)</span>
        </div>
        <div class="power-item">二级模块</div>
        <div class="power-item">三级模块</div>
        <div class="power-cont p-5 flex flex-x-between">
          <span>功能权限</span>
          <span class="c-gray-light"> (功能名值对照表：{{ actionStr }}) </span>
        </div>
      </div>
    </div>
    <div class="power-bottom">
      <!-- 一级 -->
      <div class="power-group" v-for="(itemSt, indexSt) in listData" :key="indexSt">
        <div class="power-item">
          <div class="flex flex-y-center">
            <span>{{ itemSt.key }}</span>
            <div class="flex flex-y-center">
              <span>(</span>
              <el-link type="success" @click="itemAdd(itemSt)">新增子模块</el-link>
              <el-link type="primary" @click="itemEdit(itemSt)">编辑</el-link>
              <el-link type="danger" @click="itemDelete(itemSt)">删除</el-link>
              <span>)</span>
            </div>
          </div>
        </div>
        <div class="power-cont">
          <!-- 二级 -->
          <div class="power-group" v-for="(itemNd, indexNd) in itemSt.children" :key="indexNd">
            <div class="power-item">
              <div class="flex flex-y-center">
                <span>{{ itemNd.key }}</span>
                <div class="flex flex-y-center">
                  <span>(</span>
                  <el-link type="success" @click="itemAdd(itemNd)">新增子模块</el-link>
                  <el-link type="primary" @click="itemEdit(itemNd)">编辑</el-link>
                  <el-link type="danger" @click="itemDelete(itemNd)">删除</el-link>
                  <span>)</span>
                </div>
              </div>
            </div>
            <div class="power-cont">
              <!-- 三级 -->
              <div class="power-group" v-for="(itemRd, indexRd) in itemNd.children" :key="indexRd">
                <div class="power-item">
                  <div class="flex flex-y-center">
                    <span>{{ itemRd.key }}</span>
                    <div class="flex flex-y-center">
                      <span>(</span>
                      <el-link type="success" @click="itemAdd(itemRd)">新增功能</el-link>
                      <el-link type="primary" @click="itemEdit(itemRd)">编辑</el-link>
                      <el-link type="danger" @click="itemDelete(itemRd)">删除</el-link>
                      <span>)</span>
                    </div>
                  </div>
                </div>
                <div class="power-cont action-list">
                  <!-- 四级 -->
                  <div
                    class="action-item"
                    v-for="(itemTh, indexTh) in itemRd.children"
                    :key="indexTh"
                  >
                    <span>{{ itemTh.key }}</span>
                    <div class="flex flex-y-center">
                      <span>(</span>
                      <el-link type="success" @click="itemBind(itemTh)">绑定</el-link>
                      <el-link type="primary" @click="itemEdit(itemTh)">编辑</el-link>
                      <el-link type="danger" @click="itemDelete(itemTh)">删除</el-link>
                      <span>)</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <PopupAdd
      v-if="isShowPopupAdd"
      :show.sync="isShowPopupAdd"
      :detail="activeObj"
      @success="getListData"
    />
    <PopupEdit
      v-if="isShowPopupEdit"
      :show.sync="isShowPopupEdit"
      :detail="activeObj"
      @success="getListData"
    />
    <PopupBind
      v-if="isShowPopupBind"
      :show.sync="isShowPopupBind"
      :detail="activeObj"
      @success="getListData"
    />
  </div>
</template>

<script>
import PopupAdd from './popup/popup-add'
import PopupEdit from './popup/popup-edit'
import PopupBind from './popup/popup-bind'
export default {
  components: {
    PopupAdd,
    PopupEdit,
    PopupBind
  },
  data() {
    return {
      isShowPopupAdd: false,
      isShowPopupEdit: false,
      isShowPopupBind: false,
      activeObj: {},
      listData: []
    }
  },
  computed: {
    actionStr() {
      let result = this.$constant.actionList.map((item) => {
        return `${item.key}${item.val}`
      })
      return result.join('丨')
    }
  },
  mounted() {
    this.getListData()
  },
  methods: {
    // 获取列表数据
    getListData() {
      this.$api({
        method: 'post',
        url: '/admin/gcvip/roleClass/list',
        data: {}
      }).then((res) => {
        if (res.data.state === 'ok') {
          this.listData = res.data.list
        }
      })
    },
    // 模块-新增
    itemAdd(row) {
      this.activeObj = this.$util.deepCopy(row)
      this.activeObj.module_name = row.level === 3 ? '功能' : '模块'
      this.isShowPopupAdd = true
    },
    // 模块-编辑
    itemEdit(row) {
      this.activeObj = this.$util.deepCopy(row)
      this.activeObj.module_name = row.level === 4 ? '功能' : '模块'
      this.isShowPopupEdit = true
    },
    // 模块-删除
    itemDelete(row) {
      let name = row.level < 4 ? '模块' : '功能'
      let keyArr = []
      if (row.parent_key) keyArr.push(row.parent_key)
      if (row.key) keyArr.push(row.key)
      this.$confirm(`您确认要删除该${name}(${keyArr.join('-')})?`, '提示')
        .then(() => {
          this.$api({
            method: 'get',
            url: '/admin/gcvip/roleClass/delete',
            params: {
              id: row.id
            }
          }).then((res) => {
            if (res.status === 200 && res.data.state === 'ok') {
              this.$message.success('删除成功')
              this.getListData()
            } else {
              this.$message.error(res.data.msg)
            }
          })
        })
        .catch(() => {})
    },
    // 功能-绑定
    itemBind(row) {
      this.activeObj = this.$util.deepCopy(row)
      this.isShowPopupBind = true
    }
  }
}
</script>

<style lang="less" scoped>
.power-box {
  .power-top {
    background: #fafafa;
  }
  .power-bottom {
    margin-top: -1px;
  }
  .power-group {
    display: flex;
    border: 1px solid #ddd;
    & ~ .power-group {
      margin-top: -1px;
    }
    .power-item {
      display: flex;
      align-items: center;
      padding: 5px;
      width: 200px;
      border-right: 1px solid #ddd;
      &:hover {
        background: #ecf5ff;
      }
    }

    .power-cont {
      flex: 1;
      .power-group {
        border: none;
        & ~ .power-group {
          margin-top: 0;
          .power-item,
          .action-list {
            border-top: 1px solid #ddd;
          }
        }
      }
      &.action-list {
        display: flex;
        flex-wrap: wrap;
        .action-item {
          display: flex;
          align-items: center;
          flex-wrap: nowrap;
          padding: 5px;
          &:hover {
            background: #ecf5ff;
          }
        }
      }
    }
  }

  /deep/ .el-link ~ .el-link {
    margin-left: 5px;
  }
}
</style>
