<template>
  <el-dialog
    :title="`新增${detail.module_name}`"
    width="500px"
    :visible.sync="selfShow"
    :close-on-click-modal="false"
  >
    <div>
      <e-form
        ref="refForm"
        :config="formConfig"
        :column="formColumn"
        :data.sync="selfDetail"
        :rules="rules"
      />
    </div>
    <div slot="footer">
      <el-button size="small" @click="popupCancel">取消</el-button>
      <el-button size="small" type="primary" @click="popupConfirm">确认</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      required: true
    },
    detail: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      selfShow: false,
      selfDetail: {
        parent_id: '',
        parent_key: '无',
        level: '',
        level_name: '',
        key: '',
        val: ''
      },
      formConfig: {
        inline: false,
        width: '100%'
      },
      formColumn: [
        {
          type: 'text',
          title: '上级模块名称',
          field: 'parent_key',
          disabled: true
        },
        {
          type: 'text',
          title: '当前模块级别',
          field: 'level_name',
          disabled: true
        },
        {
          type: `${this.detail.level === 3 ? 'autocomplete' : 'text'}`,
          title: `当前${this.detail.module_name}名称`,
          field: 'key',
          required: true,
          querySearch(keyword, cb) {
            let list = this.$constant.actionList.map((item) => {
              return {
                value: item.key
              }
            })
            let createFilter = (keyword) => {
              return (restaurant) => {
                return restaurant.value.toLowerCase().indexOf(keyword.toLowerCase()) === 0
              }
            }
            let results = keyword ? list.filter(createFilter(keyword)) : list
            cb(results)
          },
          fnChange: () => {
            this.setVal()
          }
        },
        {
          type: 'text',
          title: `当前${this.detail.module_name}值`,
          field: 'val',
          required: true
        }
      ],
      rules: {
        key: [{ required: true, message: `请输入${this.detail.module_name}名称` }],
        val: [{ required: true, message: `请输入${this.detail.module_name}值` }]
      },
      levelNameList: ['一级', '二级', '三级', '四级']
    }
  },
  watch: {
    selfShow(val) {
      this.$emit('update:show', val)
    }
  },
  mounted() {
    this.selfShow = this.show
    this.initSelfData()
  },
  methods: {
    // 初始化-数据
    initSelfData() {
      let { level, id, key } = this.detail
      this.selfDetail.level = level ? level + 1 : 1
      this.selfDetail.level_name = this.levelNameList[this.selfDetail.level - 1]
      if (id) this.selfDetail.parent_id = id
      if (key) this.selfDetail.parent_key = key
    },
    // 设置值
    setVal() {
      this.$constant.actionList.forEach((item) => {
        if (item.key === this.selfDetail.key) {
          this.selfDetail.val = item.val
        }
      })
    },
    // 弹窗取消
    popupCancel() {
      this.selfShow = false
      this.$emit('update:show', false)
    },
    // 弹窗确认
    popupConfirm() {
      this.$refs.refForm.$refs.refForm.validate((valid) => {
        if (valid) {
          let { parent_id, level, key, val } = this.selfDetail
          let params = {
            parent_id,
            level,
            key,
            val
          }
          this.$api({
            method: 'post',
            url: '/admin/gcvip/roleClass/saveOrUpdate',
            data: {
              ...params
            }
          }).then((res) => {
            if (res.status === 200 && res.data.state === 'ok') {
              this.$message.success('操作成功')
              this.$emit('success')
              this.popupCancel()
            } else {
              this.$message.error(res.data.msg)
            }
          })
        } else {
          return false
        }
      })
    }
  }
}
</script>

<style lang="less" scoped></style>
