<template>
  <el-dialog
    :title="`功能绑定(${selfDetail.parent_key}-${selfDetail.key})`"
    width="1000px"
    :visible.sync="selfShow"
    :close-on-click-modal="false"
  >
    <div class="content">
      <vxe-grid
        size="mini"
        border
        highlight-hover-row
        :height="256"
        :data="apiShowList"
        :columns="apiShowColumn"
      >
        <!-- slot_header -->
        <template #header_autocomplete="{ column }">
          <c-table-header
            :title="column.title"
            :field="column.field"
            :url="headerFilterUrl"
            @change="headerFilterChange"
          />
        </template>

        <!-- slot_default -->
        <template #default_operate="{ row }">
          <el-link
            class="m-l-5"
            size="mini"
            type="success"
            icon="vxe-icon-lock-fill"
            title="绑定"
            @click="itemBind(row)"
          >
            绑定
          </el-link>
        </template>
      </vxe-grid>
      <vxe-grid
        class="m-t-10"
        size="mini"
        border
        highlight-hover-row
        :height="256"
        :data="selfDetail.children"
        :columns="activeColumn"
      >
        <!-- slot_header -->
        <template #header_val>
          <span>已绑定接口(</span>
          <span class="f-bold c-green">{{ selfDetail.children.length }}</span>
          <span>)</span>
        </template>

        <!-- slot_default -->
        <template #default_key="{ row }">
          <el-input size="mini" v-model="row.key" />
        </template>
        <template #default_operate="{ rowIndex }">
          <el-link
            class="m-l-5"
            size="mini"
            type="danger"
            icon="vxe-icon-unlock-fill"
            title="解绑"
            @click="itemUnbind(rowIndex)"
          >
            解绑
          </el-link>
        </template>
      </vxe-grid>
    </div>

    <div slot="footer">
      <el-button size="small" @click="popupCancel">取消</el-button>
      <el-button size="small" type="primary" @click="popupConfirm">确认</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      required: true
    },
    detail: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      selfShow: false,
      selfDetail: {
        parent_key: '无',
        id: '',
        key: '',
        children: []
      },
      apiAllList: [],
      headerFilterUrl: '/admin/gcvip/roleClass/listRole',
      filterData: {},
      apiShowColumn: [
        {
          field: 'action_key',
          width: 800,
          title: '接口地址',
          slots: { header: 'header_autocomplete' }
        },
        { title: '操作', slots: { default: 'default_operate' } }
      ],
      activeColumn: [
        { field: 'val', width: 600, slots: { header: 'header_val' } },
        { field: 'key', width: 200, title: '备注', slots: { default: 'default_key' } },
        { title: '操作', slots: { default: 'default_operate' } }
      ]
    }
  },
  computed: {
    apiShowList() {
      let result = []
      this.apiAllList.forEach((itemSt) => {
        let isNotRepect = this.selfDetail.children.every((itemNd) => {
          return itemSt.val !== itemNd.val
        })
        if (isNotRepect) result.push(this.$util.deepCopy(itemSt))
      })
      return result
    }
  },
  watch: {
    selfShow(val) {
      this.$emit('update:show', val)
    }
  },
  mounted() {
    this.selfShow = this.show
    let { parent_key, id, key, children } = this.detail
    if (parent_key) this.selfDetail.parent_key = parent_key
    if (id) this.selfDetail.id = id
    if (key) this.selfDetail.key = key
    if (children.length) this.selfDetail.children = this.$util.deepCopy(children)
    this.getApiAllList()
  },
  methods: {
    // 头部筛选更新
    headerFilterChange(obj) {
      this.filterData[obj.field] = obj.value
      this.getApiAllList()
    },
    // 获取所有api
    getApiAllList() {
      this.$api({
        method: 'post',
        url: '/admin/gcvip/roleClass/listRole',
        data: {
          filterData: this.filterData,
          pageSize: this.$constant.pageMax
        }
      }).then((res) => {
        if (res.data.state === 'ok') {
          this.apiAllList = res.data.page.list.map((item) => {
            return {
              id: item.id,
              remark: item.remark,
              action_key: item.action_key
            }
          })
        }
      })
    },
    // 项-绑定
    itemBind(row) {
      let { id, remark, action_key } = row
      let result = {
        id,
        key: remark,
        val: action_key
      }
      this.selfDetail.children.push(result)
    },
    // 项-解绑
    itemUnbind(index) {
      this.selfDetail.children.splice(index, 1)
    },
    // 弹窗取消
    popupCancel() {
      this.selfShow = false
      this.$emit('update:show', false)
    },
    // 弹窗确认
    popupConfirm() {
      let { id, children } = this.selfDetail
      let params = {
        id,
        children
      }
      this.$api({
        method: 'post',
        url: '/admin/gcvip/roleClass/saveOrUpdateApi',
        data: {
          ...params
        }
      }).then((res) => {
        if (res.status === 200 && res.data.state === 'ok') {
          this.$message.success('操作成功')
          this.$emit('success')
          this.popupCancel()
        } else {
          this.$message.error(res.data.msg)
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.content {
  /deep/ .el-link {
    vertical-align: inherit;
  }
}
</style>
